/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--style2 js-anim  --anim5 --anim-s3 --full --parallax" name={"uvod"} style={{"backgroundColor":"rgba(32, 32, 32, 1)"}} fullscreen={true} parallax={true} anim={"5"} animS={"3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--20 pl--25 pr--25 pt--20 flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left pb--16 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Title className="title-box title-box--shadow5 fs--102" content={"<span style=\"color: white; font-style: italic;\">Kontakt</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-style: italic;\">+420 494 909 023 - office&nbsp;<br>+420 703 635 038 - kontrolní technik<br>+420 702 868 880 - servisní technici&nbsp;</span><br></span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" content={"<span style=\"color: var(--color-custom-1); font-style: italic;\">fit-okna@seznam.cz</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--02 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Text className="text-box fs--24" style={{"maxWidth":900}} content={"<span style=\"font-style: italic;\">Prosetín 2&nbsp;<br>539 76 Prosetín</span><br>"}>
              </Text>

              <Text className="text-box fs--24" style={{"maxWidth":900}} content={"<span style=\"font-style: italic;\">IČ: 02779544<br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--60" name={"program"} style={{"backgroundColor":"rgba(160, 160, 160, 1)"}}>
          
          <ColumnWrap className="column__flex el--1 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-style: italic;\">Domluvit schůzku</span>"}>
              </Title>

              <Text className="text-box text-box--center fs--26" content={"<span style=\"font-style: italic; color: var(--color-blend--50); font-weight: bold;\">zde prosím vyplňte kontakt na Vás a krátký popis požadavku, ozveme se Vám v nejbližším možném termínu</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"formular"} style={{"backgroundColor":"rgba(160, 160, 160, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--30 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <ContactForm className="--shape2 --style3 fs--20" style={{"maxWidth":1544}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}